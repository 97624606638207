import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "@material-ui/icons/Close";

import {

  Button,

  DialogContent,
  Dialog,

  makeStyles,

  TextField,
  InputAdornment,

} from "@material-ui/core";
import swal from "sweetalert";

import RegexTextField from "../../CourseCreation/RegexTextField";
import config from "../../../../config/aws-exports";
import Cviewstyle from "./Podcast.module.scss"

import axios from "axios";
import { API } from "aws-amplify";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "1100px",
    height: "660px",
    borderRadius: ".8rem",
    overflowX: "hidden",
    overflowY: "auto",
  },
  modal: {
    display: "flex",
    overflowY: "auto !important",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.73)",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "98%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const CreatePodcast = ({
  objOpen,
  setObjOpen,
  cNugget,
  setNuggets,
  nuggets,
  selectedObj,
  setSelectedObj,
  folder
}) => {
  const iniotypes = [
    { type: "Narrative", id: "2", img: "html" },
    { type: "PDF", id: "3", img: "pdf" },
    { type: "Quiz", id: "4", img: "quiz" },
    { type: "Interactivity", id: "5", img: "Interactivity" },
    { type: "Video", id: "6", img: "video" },
    { type: "Audio", id: "7", img: "audio" },
    { type: "Scrom", id: "8", img: "scorm" },
  ];

  const [selectType, setSelectType] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const classes = useStyles();
  const onlyAlphanumericRegex = /[^0-9]/gi;
  const refobject = useRef({});
  const instanceRef = useRef(null);

  useEffect(() => {

  }, []);

  const handleClose = () => {
    setObjOpen(false)
  };


  const finalSave = async () => {
    selectedObj.otherdata.dur =
      Number(selectedObj.otherdata.min) * 60 +
      Number(selectedObj.otherdata.sec);
    let file;
    let id = Math.floor(Math.random() * (999 - 100 + 1) + 100);
    let filename = "";
    filename = id + selectedFile.name;
    let object = {
      otitle: selectedObj.otherdata.title,
      odescription: selectedObj.otherdata.desc,
      otype: selectedObj.otype,
      oduration: selectedObj.otherdata.dur,
      ourl: filename,
      filechanges: true,
      newfile: filename,
      miniassignmenttype: selectedObj.otherdata.miniassignmenttype,
    };

    if (cNugget !== undefined && cNugget.nid !== undefined) {
      if (cNugget.objects.length > 0) {
        object.oid =
          cNugget.nid +
          "-" +
          (Number(
            cNugget.objects[cNugget.objects.length - 1].oid.substr(
              cNugget.objects[cNugget.objects.length - 1].oid.length - 1
            )
          ) +
            1);
      } else {
        object.oid = cNugget.nid + "-1";
      }
      if (cNugget.cedit !== undefined) {
        var filetype = filename.split(".").pop();
        object.ourl = object.oid + "." + filetype
      }
    }
    refobject.current = object;

    var body = {
      filename: object.ourl,
      folder: folder,
    };

    file = selectedFile;
    body.filetype = selectedFile.type;
    uploadfile(body, file);

  };
  const checkData = async () => {
    if (
      selectedObj.otherdata.title === undefined ||
      selectedObj.otherdata.title == "" ||
      selectedObj.otherdata.title.replace(/\s/g, "").length === 0
    ) {
      selectedObj.otherdata.tval = true;

      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));

      return;
    }
    if (
      selectedObj.otherdata.desc === undefined ||
      selectedObj.otherdata.desc == "" ||
      selectedObj.otherdata.desc.replace(/\s/g, "").length === 0
    ) {
      selectedObj.otherdata.dsval = true;
      selectedObj.otherdata.tval = false;
      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));
      return;
    }
    if (
      selectedObj.otherdata.min === undefined ||
      selectedObj.otherdata.min == "" ||
      selectedObj.otherdata.min.replace(/\s/g, "").length === 0
    ) {
      selectedObj.otherdata.dsval = false;
      selectedObj.otherdata.tval = false;
      selectedObj.otherdata.mval = true;
      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));
      return;
    }
    if (
      selectedObj.otherdata.sec === undefined ||
      selectedObj.otherdata.sec == "" ||
      selectedObj.otherdata.sec.replace(/\s/g, "").length === 0
    ) {
      selectedObj.otherdata.dsval = false;
      selectedObj.otherdata.tval = false;
      selectedObj.otherdata.mval = false;
      selectedObj.otherdata.sval = true;
      setSelectedObj((prevselectedObj) => ({
        ...prevselectedObj,
        ...selectedObj,
      }));
      return;
    }
    if (selectedObj.stype !== undefined && selectedObj.stype == "chtml") {
      const savedData = await instanceRef.current.save();
      if (savedData.blocks.length === 0) {
        selectedObj.otherdata.dsval = false;
        selectedObj.otherdata.tval = false;
        selectedObj.otherdata.mval = false;
        selectedObj.otherdata.sval = false;
        selectedObj.ferror = true;
        setSelectedObj((prevselectedObj) => ({
          ...prevselectedObj,
          ...selectedObj,
        }));
        return;
      }
    } else if (

      selectedObj.sobject == "Interactivity" ||
      selectedObj.sobject == "scorm" ||
      (selectedFile === undefined &&
        selectedObj.sobject !== "youtube" &&
        selectedObj.sobject !== "vimeo")
    ) {

    }

    finalSave();
  };

  const changeHandler = (event) => {
    if (event.target.files[0] !== undefined) {
      const file = event.target.files[0];


      if (file.type.startsWith("audio/")) {
        const fileSize = file.size;
        const size = Math.round(fileSize / 1024);

        if (size <= 25 * 1024) {
          setSelectedFile(file);
          selectedObj.otherdata.files = file.name;
          setSelectedObj(selectedObj);
        } else {
          swal({
            icon: "error",
            title: "Oops...",
            text: "Invalid file size, please select a file less than or equal to 25MB in size",
          });
        }
      } else {
        swal({
          icon: "error",
          title: "Invalid file type",
          text: "Please select an audio file",
        });
      }
    }
  };


  async function uploadfile(body, file) {
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };


    setLoading(true)

    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getpreSignedURL",
        bodyParam
      );
      let ftype = "";
      if (selectedObj.stype !== undefined && selectedObj.stype == "chtml") {
        ftype = "text/html";
      } else if (selectedObj.otype == "quiz") {
        ftype = "application/json";
      } else {
        ftype = selectedFile.type;
      }

      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": ftype,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then(() => {

          if (refobject.current.qtype == 2 || refobject.current.qtype == "2") {
            refobject.current.cutoff = selectedObj.quizdata.cutoff;
          }
          nuggets[cNugget.idx].nchanges = true;
          nuggets[cNugget.idx].objects.push(refobject.current);
          setNuggets(nuggets);
          setObjOpen(false);
          setLoading(false)
        })
        .catch((error) => {
          // setisBookmarkedLoaded(false);
          console.error("There was an error!", error);
        });
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  return (
    <div >
      <Dialog open={objOpen} scroll="body" maxWidth="md" id="mymodal">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1.5rem",
            backgroundColor: "#393939",
            color: "#fff",
          }}
        >
          <h3>
            Add Podcast
          </h3>
          <button
            onClick={handleClose}
            style={{
              border: "none",
              background: "transparent",
              cursor: "pointer",
              color: "white",
            }}
          >
            <CloseIcon fontSize="large" />
          </button>
        </div>

        <DialogContent>
          <div style={{ margin: "20px 60px", overflow: "hidden" }}>
            {selectedObj.error1 && (
              <p className={Cviewstyle.verror1}>
                {selectedObj.sobject}
                title cannot be empty
              </p>
            )}

            <form autoComplete="off">
              <TextField
                label="Title"
                id="outlined-margin-normal"
                className={classes.textField}
                error={selectedObj.otherdata.tval}
                helperText={
                  selectedObj.otherdata.tval ? "Please Enter Title" : ""
                }
                margin="normal"
                variant="outlined"
                onChange={(e) => {
                  selectedObj.otherdata.title = e.target.value;
                  setSelectedObj(selectedObj);
                }}
              />
              <TextField
                label="Description"
                id="outlined-margin-normal"
                className={classes.textField}
                error={selectedObj.otherdata.dsval}
                helperText={
                  selectedObj.otherdata.dsval
                    ? "Please Enter Description"
                    : ""
                }
                margin="normal"
                variant="outlined"
                onChange={(e) => {
                  selectedObj.otherdata.desc = e.target.value;
                  setSelectedObj(selectedObj);
                }}
              />
              <RegexTextField
                regex={onlyAlphanumericRegex}
                label="Duration"
                error={selectedObj.otherdata.mval}
                helperText={
                  selectedObj.otherdata.mval ? "Please Enter Min" : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Min</InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  selectedObj.otherdata.min = e.target.value;
                  setSelectedObj(selectedObj);
                }}
              />
              <RegexTextField
                regex={onlyAlphanumericRegex}
                label=""
                error={selectedObj.otherdata.sval}
                helperText={
                  selectedObj.otherdata.sval ? "Please Enter Sec" : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Sec</InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  selectedObj.otherdata.sec = e.target.value;
                  setSelectedObj(selectedObj);
                }}
              />
            </form>
          </div>

          <div className={Cviewstyle.insidediv}>
            <div className={Cviewstyle.centeritem}>
              <input
                type="file"
                id="files"
                style={{
                  visibility: "hidden",
                  position: "absolute",
                }}
                onChange={changeHandler}
                accept={selectedObj.afile}
              />
              <label for="files">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"

                  style={{
                    background: "transparent",
                    border: "1px solid var(--button-background)",
                    borderRadius: "5px",
                    height: "30px",
                    color: "var(--button-background)",
                    width: "10rem",
                    fontWeight: "bold",
                    fontSize: "16px",
                    padding: "0px 20px",
                  }}
                >
                  Browse
                </Button>
              </label>
            </div>

            {selectedObj.otherdata.files != "" && (
              <p className={Cviewstyle.pfile}>
                {selectedObj.otherdata.files}
              </p>
            )}
            {selectedObj.otherdata.files == "" && (
              <p className={Cviewstyle.pfile}>
                {selectedObj.sobject}
                file not selected{" "}
              </p>
            )}
            {selectedObj.ferror && (
              <p
                className={Cviewstyle.pfile}
                style={{ color: "red" }}
              >

                Please select file{" "}
              </p>
            )}
          </div>
          <div style={{ textAlign: "center", padding: "2rem" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ background: selectedObj.otherdata.files != "" ? " var(--sub-heading-color)" : "gray", width: "5rem" }}
              onClick={() => checkData()}
            >
              {
                loading ? ("Loading...") : ("Save")
              }
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default CreatePodcast