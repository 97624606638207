//Dependency Imports
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Skeleton from "@material-ui/lab/Skeleton";
import CloseIcon from "@material-ui/icons/Close";
import {

    Input,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import swal from "sweetalert";
import { ReactComponent as PodCastIcon } from '../../../../assets/svg/Icon fa-solid-podcast.svg';

//Local Imports
import config from "../../../../config/aws-exports";
import { authData } from "../../../../redux/auth/authSlice";
import AudioPlayer from "./AudioPlayer";
import Pod from "./Podcast.module.scss"
import addpluse from "../../../../assets/images/add.png";
import CreatePodcast from "./CreatePodcastObject";
import { Constants } from "../../../../config/constants";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingTop: "20px",

        overflowY: "auto",
    },
    heading: {

    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },

    tooltipFull: {
        fontSize: 30,
    },

}));

let styles = {
    editButton: {
        color: "white",
        borderStyle: "groove ",
        borderColor: Constants.main_color_2,
        height: "30px ",
        lineHeight: "0px ",
        width: "150px",
        marginTop: "15px",
        marginRight: "50px",
        backgroundColor: Constants.main_color_2,
        fontSize: "15px ",
        borderRadius: "3px ",
        float: "right",
        fontWeight: "bold",
    },
    enableDisable: {
        color: "black",
        borderStyle: "groove ",
        borderColor: "black",
        height: "30px ",
        lineHeight: "0px ",
        width: "150px",
        backgroundColor: "white",
        fontSize: "15px ",
        borderRadius: "3px ",
        float: "right",
    },
};
const Podcast = ({ pid, bpid, courseid, bcourseid }) => {

    const classes = useStyles();

    let userDetails = useSelector(authData);
    const [nuggets, setNuggets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openAddPodCast, setOpenAddPodCast] = useState(false);
    const [nopen, setNopen] = useState(false);
    const [cNugget, setcNugget] = useState({});
    const [editObj, setEditObj] = useState({
        editbtn: true,
        meditmode: false,
        oeditmode: false,
        nuggetchanges: false,
        topicimgchanges: false,
    });
    const [curObject, setcurObject] = useState();
    const [objOpen, setObjOpen] = useState(false);
    const [selectedObj, setSelectedObj] = useState({});
    let topicdata = userDetails.curprg;
    const [topicData, setTopicData] = useState(topicdata);
    const [enableItem, setEnableItem] = useState({
        nenabled: "",
        nid: "",
        idx: "",
        ntitle: "",
    });
    const [folder, setFolder] = useState(Math.round(new Date().getTime() / 1000));
    const refUnitValue = useRef("");
    const refnuggetValue = useRef("");
    const handleOpenClose = (state) => {
        setOpen(false)
    };

    useEffect(() => {
        getPodcast()
    }, []);

    async function getPodcast() {
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                pid: pid,
                bpid: bpid,
                courseid: courseid,
                bcourseid: bcourseid,
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        setIsLoading(true)
        try {

            const response = await API.post(
                config.aws_cloud_logic_custom_name,
                "/getpodcast",
                bodyParam
            );

            if (response != null || response != []) {

                if (response && response.nuggets != undefined) {
                    setNuggets(response.nuggets);
                }

                setIsLoading(false)

            } else {

            }

        } catch (error) {
            console.error(error);
        }
    }
    async function savePodcastDetails() {

        if (nuggets && nuggets.length == 0) {

            swal(
                "Oops!",
                "You need to add atleast 1 Module",
                "error"
            );
            return;


        }

        for (let k = 0; k < nuggets.length; k++) {
            if (nuggets[k].objects.length == 0) {
                swal(
                    "Oops!",
                    "You need to add atleast 1 Content in each Module",
                    "error"
                );
                return;

            }
        }
        setIsLoading(true)
        try {
            const bodyParam = {
                body: {
                    oid: config.aws_org_id,
                    NAME: userDetails.name,
                    folder,
                    tid: courseid,
                    ttitle: topicData.ttitle,
                    tdescription: topicData.tdescription,
                    freenavigation: topicData.freenavigation,
                    certification: topicData.certification,
                    tcertauth: topicData.tcertauth,
                    tnoofcredits: topicData.tnoofcredits,
                    tduration: topicData.tduration,
                    toduration: topicData.toduration,
                    noofnuggets: nuggets.length,
                    nuggets,
                    version: 1,
                    tobj: topicData.tobj,
                    tvid: topicData.tvid,
                    tdoc: topicData.tdoc,
                    tfq: topicData.tfq,
                    tsq: topicData.tsq,
                    tasmt: topicData.tasmt,
                },
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            };
            if (userDetails.curprgcou !== undefined) {
                if (userDetails.curprgcou.bpid !== undefined) {
                    bodyParam.body.pid = userDetails.curprgcou.pid;
                    bodyParam.body.bpid = userDetails.curprgcou.bpid;
                }

            }

            const getTopicDetails = await API.post(
                config.aws_cloud_logic_custom_name,
                "/addPodCast",
                bodyParam
            );

            setOpenAddPodCast(false);
            editObj.meditmode = false;
            setEditObj({ ...editObj });
            getPodcast()

        } catch (error) {
            setIsLoading(false);
            console.error("createtopic" + error);
        }
    }
    function onClickObject(item) {
        setOpen(true);
        setcurObject(item)
    }
    const enableDisablePost = async (status, nid, idx, title) => {
        setIsLoading(true);

        const bodyParam = {
            body: {
                topicid: courseid,
                oid: config.aws_org_id,
                btid: bcourseid,
                nid,
                disable: !status,
                bpid: userDetails.curprgcou.bpid,
                email: enableItem.email,
                emailIntern: enableItem.sendmailIntern,
                bname: userDetails.curprgcou.bname,
                disablebyemail: userDetails.email,
                disablebyName: userDetails.name,
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };

        if (
            userDetails.curprg !== undefined &&
            userDetails.curprg.btid !== undefined
        ) {
            bodyParam.body.btid = userDetails.curprg.btid;
        }


        try {
            await API.post(
                config.aws_cloud_logic_custom_name,
                "/enablePodcast",
                bodyParam
            );


            nuggets[idx].nenabled = !status;
            setIsLoading(false);


        } catch (error) {
            console.log("enableNuggetserre", error);
        }


        setEnableItem(enableItem);
    };

    const saveNugget = () => {
        let objs = {};
        objs.nid = nuggets.length + 1;
        objs.nenabled = false;
        objs.ntitle = refnuggetValue.current;
        objs.objects = [];
        objs.unit = refUnitValue.current;
        nuggets.push(objs);
        setNuggets(nuggets);
        refUnitValue.current = "";
        refnuggetValue.current = "";
        setNopen(false);

    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                closeAfterTransition
            >
                <Fade in={open}>


                    <AudioPlayer curObject={curObject} handleOpenClose={handleOpenClose} />

                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={objOpen}
                closeAfterTransition
            >
                <Fade in={objOpen}>
                    <CreatePodcast
                        objOpen={objOpen}
                        setObjOpen={setObjOpen}
                        cNugget={cNugget}
                        setNuggets={setNuggets}
                        nuggets={nuggets}
                        selectedObj={selectedObj}
                        setSelectedObj={setSelectedObj}
                        folder={folder}
                    />

                </Fade>
            </Modal>

            {
                openAddPodCast ? (<div>
                    <div className={Pod.buttonholder1}>
                        <button
                            style={{
                                background: "transparent",
                                border: "1px solid var(--button-background)",
                                borderRadius: "5px",
                                height: "30px",
                                color: "var(--button-background)",
                                fontFamily: "nunito",
                                fontWeight: "bold",
                                fontSize: "16px",
                                padding: "0px 20px",
                            }}
                            className={Pod.addbutton}
                            onClick={() => {
                                setOpenAddPodCast(false);
                                editObj.meditmode = false;
                                setEditObj({ ...editObj });
                                getPodcast();
                            }}
                        >
                            Discard
                        </button>

                        <button
                            style={{
                                background: "var(--button-background)",
                                border: "none",
                                borderRadius: "5px",
                                height: "30px",
                                color: "var(--button-text)",
                                fontFamily: "nunito",
                                fontWeight: "bold",
                                fontSize: "16px",
                                padding: "0px 20px",
                            }}
                            className={Pod.addbutton}
                            onClick={() => {

                                savePodcastDetails();
                            }}
                        >
                            Save
                        </button>

                    </div></div>) : (

                    <div
                        style={{ padding: "2rem", textAlign: "right" }}
                    > <button
                        style={{
                            background: "var(--button-background)",
                            border: "none",
                            borderRadius: "5px",
                            height: "30px",
                            color: "var(--button-text)",
                            fontFamily: "nunito",
                            fontWeight: "bold",
                            fontSize: "16px",
                            padding: "0px 20px",
                        }}
                        className={Pod.addbutton}
                        onClick={() => {
                            setOpenAddPodCast(true);
                            editObj.meditmode = true;
                            editObj.editbtn = false;
                            setEditObj({ ...editObj });

                        }}
                    >
                            Add
                        </button></div>
                )
            }

            <div>

                <div className={classes.root}>
                    {
                        <>{isLoading ? <Skeleton style={{ height: "7rem", }} /> : <div>
                            {
                                nuggets && nuggets.length > 0 ? (<>
                                    {nuggets.map(
                                        (
                                            { nid, unit, ntitle, nduration, objects, nenabled },
                                            idx
                                        ) => (
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    {!editObj.meditmode && (
                                                        <>
                                                            <h2 className="size-m-b">{ntitle}</h2>
                                                        </>
                                                    )}
                                                    {editObj.meditmode && (
                                                        <>
                                                            <Input
                                                                defaultValue={ntitle}
                                                                className={Pod.inputsize}
                                                                onClick={(event) => event.stopPropagation()}
                                                                onFocus={(event) => event.stopPropagation()}
                                                                inputProps={{ "aria-label": "description" }}

                                                            />
                                                            <button
                                                                onClick={() => {
                                                                    nuggets.splice(idx, 1);
                                                                    setNuggets([...nuggets]);
                                                                }}
                                                                style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "bold",
                                                                    padding: "0px 20px",
                                                                    background: "#e35f14",
                                                                    border: "none",
                                                                    borderRadius: "5px",
                                                                    width: "194px",
                                                                    color: "#fff",
                                                                }}
                                                            >
                                                                Remove Module
                                                            </button>{" "}
                                                        </>
                                                    )}

                                                    {
                                                        !editObj.meditmode && (<p
                                                            style={{
                                                                fontSize: "14px",
                                                                position: "absolute",
                                                                right: "60px",
                                                            }}
                                                        >
                                                            {nenabled ? (
                                                                <>
                                                                    <button
                                                                        style={styles.enableDisable}
                                                                        onClick={(event) => {
                                                                            enableItem.nenabled = nenabled;
                                                                            enableItem.nid = nid;
                                                                            enableItem.idx = idx;
                                                                            enableItem.ntitle = unit;
                                                                            enableItem.sendmailIntern = true;
                                                                            enableItem.email = false;
                                                                            setEnableItem(enableItem);
                                                                            enableDisablePost(nenabled, nid, idx, unit);
                                                                            event.stopPropagation();
                                                                        }}
                                                                    >
                                                                        Disable
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <button
                                                                        style={styles.enableDisable}
                                                                        onClick={() => {
                                                                            enableItem.nenabled = nenabled;
                                                                            enableItem.nid = nid;
                                                                            enableItem.idx = idx;
                                                                            enableItem.ntitle = unit;
                                                                            setEnableItem(enableItem);
                                                                            enableDisablePost(
                                                                                enableItem.nenabled,
                                                                                enableItem.nid,
                                                                                enableItem.idx,
                                                                                enableItem.ntitle
                                                                            );

                                                                        }}
                                                                    >
                                                                        Enable
                                                                    </button>
                                                                </>
                                                            )}
                                                        </p>)
                                                    }


                                                </AccordionSummary>

                                                <div>
                                                    <div className="display-flex padding2"></div>
                                                    {objects.map((object, oidx) => (
                                                        <div
                                                            className="flex size"
                                                            style={{
                                                                width: "calc(100% - 4.5%)",
                                                            }}
                                                        >
                                                            <PodCastIcon style={{ height: "2rem", width: "2rem" }} />

                                                            <p style={{ cursor: "pointer" }}>
                                                                <a
                                                                    style={{ paddingLeft: "10px" }}
                                                                    href
                                                                    onClick={() => {
                                                                        onClickObject(object);
                                                                    }}
                                                                >
                                                                    {object.otitle}
                                                                </a>
                                                            </p>
                                                            <span>
                                                                {editObj.meditmode && (
                                                                    <button
                                                                        onClick={() => {
                                                                            nuggets[idx].objects.splice(oidx, 1);
                                                                            setNuggets([...nuggets]);
                                                                        }}
                                                                        style={{
                                                                            border: "none",
                                                                            background: "transparent",
                                                                        }}
                                                                    >
                                                                        <CloseIcon
                                                                            fontSize="10px"
                                                                            style={{ color: "red" }}
                                                                        />
                                                                    </button>
                                                                )}
                                                            </span>
                                                        </div>
                                                    ))}
                                                    {editObj.meditmode && (
                                                        <div className={Pod.addnugget}>
                                                            <div>
                                                                <p
                                                                    style={{
                                                                        fontSizesize: "14px",
                                                                        margin: "5px 0",
                                                                        textAlign: "center",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                        setcNugget({
                                                                            nid,
                                                                            ntitle,
                                                                            objects,
                                                                            idx,
                                                                            cedit: true,
                                                                        });

                                                                        let obj = {};
                                                                        obj.otherdata = { title: "", desc: "", dur: "", files: "" };
                                                                        obj.id = 10
                                                                        obj.audiot = true;
                                                                        obj.sobject = "Audio";
                                                                        obj.otherdata.ytcode = "";
                                                                        obj.otype = "podcast";
                                                                        obj.afile = ".mp3";

                                                                        setObjOpen(true);
                                                                        setSelectedObj(obj);

                                                                    }}
                                                                >
                                                                    <img
                                                                        style={{
                                                                            height: "25px",
                                                                            border: "none",
                                                                            cursor: "pointer",
                                                                            margin: "0 15px",
                                                                        }}
                                                                        src={addpluse}
                                                                    />
                                                                    Add objects
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Accordion>)

                                    )

                                    }</>) : (


                                    <>


                                        {
                                            !openAddPodCast && (<h3 style={{ textAlign: "center", fontWeight: "600" }}>No Data</h3>)
                                        }



                                    </>

                                )
                            }
                            {openAddPodCast && (
                                <div className={Pod.addnugget}>
                                    <div>
                                        <p
                                            style={{
                                                fontSizesize: "14px",
                                                margin: "5px 0",
                                                textAlign: "center",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                setNopen(true);
                                            }}
                                        >
                                            <img

                                                alt=""
                                                style={{
                                                    height: "25px",
                                                    border: "none",
                                                    cursor: "pointer",
                                                    margin: "0 15px",
                                                }}
                                                src={addpluse}
                                            />
                                            Add Nugget
                                        </p>
                                    </div>

                                    <Dialog
                                        open={nopen}
                                        fullWidth={true}  
                                        maxWidth="sm"
                                        aria-labelledby="form-dialog-title"
                                    >
                                        <DialogTitle id="form-dialog-title">
                                            <h2 className={Pod.popadd}>Add Nugget</h2>
                                        </DialogTitle>
                                        <form onSubmit={(event) => event.preventDefault()}>
                                            <DialogContent>
                                                <div className={Pod.inputholder}>
                                                    <input
                                                        defaultValue={refUnitValue.current}
                                                        id="standard-error-helper-text"
                                                        placeholder="Unit Name"
                                                        type="text"
                                                        className={Pod.inputbox}
                                                        onChange={(event) => {
                                                            const { value } = event.target;
                                                            refUnitValue.current = value;
                                                        }}
                                                    />
                                                    <input
                                                        defaultValue={refnuggetValue.current}
                                                        id="name"
                                                        placeholder="Nugget Name"
                                                        type="text"
                                                        className={Pod.inputbox}
                                                        onChange={(event) => {
                                                            const { value } = event.target;
                                                            refnuggetValue.current = value;
                                                        }}
                                                    />
                                                </div>
                                            </DialogContent>
                                            <div className={Pod.buttonholder}>
                                                <DialogActions>
                                                    <button
                                                        className={Pod.cancel}
                                                        onClick={() => {
                                                            refUnitValue.current = "";
                                                            refnuggetValue.current = "";
                                                            setNopen(false);
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            if (
                                                                refUnitValue.current === undefined ||
                                                                refUnitValue.current.toString().replace(/\s/g, "").length === 0
                                                            ) {
                                                                swal("Please Enter Unit Name");
                                                                return;
                                                            } else if (
                                                                refnuggetValue.current === undefined ||
                                                                refnuggetValue.current.toString().replace(/\s/g, "").length === 0
                                                            ) {
                                                                swal("Please Enter Nugget Name");
                                                                return;
                                                            } else {
                                                                let objs = {};
                                                                objs.nid = nuggets.length + 1;
                                                                objs.nenabled = false;
                                                                objs.ntitle = refnuggetValue.current;
                                                                objs.objects = [];
                                                                objs.unit = refUnitValue.current;
                                                                nuggets.push(objs);
                                                                setNuggets(nuggets);
                                                                refUnitValue.current = "";
                                                                refnuggetValue.current = "";
                                                                setNopen(false);
                                                            }
                                                        }}
                                                        className={Pod.save}
                                                    >
                                                        Save
                                                    </button>
                                                </DialogActions>
                                            </div>
                                        </form>
                                    </Dialog>

                                </div>)

                            } </div>}</>
                    }


                </div>
            </div>


        </div >
    )
}

export default Podcast